import Link from "next/link";
import React from "react";
import Icon from "../ui/Icon";
import Picture from "../picture/Picture";
import { EntityType, InstagramType } from "@/types";

interface Props {
  instagramPostData: EntityType<InstagramType>;
}

export default function InstagramCard({ instagramPostData }: Props) {
  return (
    <div className="instagram_item md:max-w-[364px] max-w-[167px] flex flex-col group/instagram_item transition-all duration-300 hover:shadow-main-box-shadow md:mb-0 mb-[-45px] md:static relative hover:z-10">
      <div className="instagram_item-img md:w-[364px] md:h-[364px] w-[167px] h-[167px] bg-disable-text-color border-[10px] border-white overflow-hidden">
        {instagramPostData.attributes.image && (
          <Picture
            alt="insta-post"
            src={instagramPostData.attributes.image.data?.attributes.url ?? ""}
            width={364}
            height={364}
            className="min-h-full"
          />
        )}
      </div>
      <div className="instagram_item-content bg-white flex items-center justify-between md:py-3 py-2 md:px-6 px-3 invisible opacity-0 group-hover/instagram_item:visible group-hover/instagram_item:opacity-100 transition-all duration-300">
        <Link href="#" className="instagram_item-tag text-secondary-text-color hidden md:block">
          @{instagramPostData.attributes.user}
        </Link>
        <div className="instagram_item-info flex md:gap-4 gap-1 items-center md:w-fit w-full justify-between">
          <div className="instagram_item-heart flex gap-1 items-center justify-center">
            <Icon iconName="i-likegold-icon" />
            <p>{instagramPostData.attributes.like}</p>
          </div>
          <div className="instagram_item-comment flex gap-1 items-center justify-center">
            <Icon iconName="i-commentgold-icon" />
            <p>{instagramPostData.attributes.comment}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
