import { ApiResponseSingleType, ApiResponseType, BlogType } from "@/types";
import apiClient from "./config/ApiClient";

interface blogID {
  blogID: string | string[] | undefined;
}

export async function getBlogsApiCall(): Promise<ApiResponseType<BlogType>> {
  return await apiClient.get("/blogs", {
    params: {
      populate: "image",
    },
  });
}

export async function getSingleBlogApiCall({
  blogID,
}: blogID): Promise<ApiResponseSingleType<BlogType>> {
  return apiClient.get(`/blogs/${blogID}`, {
    params: {
      populate: "*",
    },
  });
}
