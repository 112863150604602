import { ApiResponseType, CategoryType } from "@/types";
import apiClient from "./config/ApiClient";

interface Props {
  filters?: {};
}

export async function getHomePageCategoriesApiCall(): Promise<ApiResponseType<CategoryType>> {
  return await apiClient.get("/categories", {
    params: {
      populate: "image",
      filters: {
        home_page: {
          $eq: true,
        },
      },
    },
  });
}

export async function getCategoriesApiCall(): Promise<ApiResponseType<CategoryType>> {
  try {
    return await apiClient.get("/categories", {
      params: {
        populate: "*",
      },
    });
  } catch (error) {
    console.error("Error making API call:", error);
    throw error; // re-throw to ensure the query handles it
  }
}

export async function getSelectedCategoriesApiCall({
  filters,
}: Props): Promise<ApiResponseType<CategoryType>> {
  return await apiClient.get("/categories", {
    params: {
      populate: "*",
      filters: filters,
    },
  });
}
